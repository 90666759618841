#header {
    background-color: #40b3a2;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
   
  .banner-content {
    width: 55%;
    height: 68%;
  } 

  .image-container {
    width: 40vw;
    height: 75vh;
  }

  .personal-image {
    background-image: url('../images/personal-pic.png');
    background-size: cover;
    background-position: center; 
    padding: 0.5em;
    background-color: #40b3a2;
    width: 100%;
    height: 100%;
  }
  
  .text-over-name {
    line-height: 1.5em;
    font-size: 4em;
    color: #000;
  }
  
  .text-over {
    line-height: 1.5em;
    font-size: 1.3em;
    color: #000;
  }

  .get-in-touch {
    margin-top: 2.5em;
  }

  .get-in-touch a {
    text-decoration: none;
    background-color: transparent;
    color: #000;
    line-height: 1.5em;
    font-size: 1em;
    border: 1px solid #000;
    padding: 0.9em 2.5em;
  }

  @media (hover: hover) { 
    .get-in-touch a:hover{
      box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px 0px;
    }
 }

 @media screen and (max-width: 1470px) {
  .image-container {
    width: 43vw;
    height: 67vh;
  }
 }

  @media screen and (max-width: 1200px) {
    .text-over-name {
      font-size: 3em;
    }
   }

   @media screen and (max-width: 1120px) {
    #header {
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center; 
    }

    .image-container {
      width: 50vw;
      height: 70vh;
      align-items: center;
      margin-left: 20em;
    }

    .personal-image {
      background-image: url('../images/personal-pic-forward.png');  
      width: 100%;
      height: 100%;
    }

    .banner-content {
      align-items: flex-end;
      margin-top: 150px;
      width: 55%;
      height: 40%;
    }
   }

   @media screen and (max-height: 600px) {
    .image-container {
      width: 300px;
      height: 300px;
      margin-left: 18em;
    }
   }


   @media screen and (max-width: 900px) {
    .image-container {
      width: 450px;
      height: 450px;
      margin-left: 18em;
    }
   }

   @media screen and (max-width: 840px) {
    .text-over-name {
      font-size: 2.3em;
    }

    .text-over {
      font-size: 1.1em;
    }

    .get-in-touch a {
      font-size: 1em;
      padding: 0.9em 2em;
    }

    .get-in-touch {
      margin-top: 2em;
    }
   }

   @media screen and (max-width: 740px) {
    .image-container {
      width: 450px;
      height: 400px;
      margin-left: 10em;
    }
   }

   @media screen and (max-width: 640px) {
    .image-container {
      width: 400px;
      height: 400px;
      margin-left: 5em;
    }

    .banner-content {
      z-index: 1;
    }

    .text-over-name {
      line-height: 1.1em;
    }
   }

   @media screen and (max-width: 480px) {
    .text-over-name {
      font-size: 2em;
    }
    .banner-content {
      width: 60%;
      height: 55%;
    } 
    .image-container {
      width: 360px;
      height: 345px;
      margin-left: 0;
    }
   }

   @media screen and (max-width: 400px) {
    .image-container {
      width: 300px;
      height: 300px;
    }
   }

   @media screen and (max-width: 305px) {
    .image-container {
      width: 280px;
      height: 300px;
    }
   }