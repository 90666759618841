#workflow {
    background-color: #f4f3f1;
    padding-top: 4em;
}
  
  .workflow-page-container {
    display: inline-grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 1.5em;
    row-gap: 1.5em;
    text-align: center;
  }
  
  .workflow-card p {
    background-color: #fff;
  }

  .workflow-card-up p {
    background-color: #fff;
  }
  
  .workflow-card {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 1.2em;
    background-color: #fff;
    padding: 45px 35px;
    border-radius: 15px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    margin-top: 6em;
  }

  .workflow-card-up {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 1.2em;
    background-color: #fff;
    padding: 45px 35px;
    border-radius: 15px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    margin: 0 0 6em 0;
  }
  
  .workflow-card img {
    background-color: #fff;
    width: 50px;
  }

  .workflow-card-up img {
    background-color: #fff;
    width: 50px;
  }
  
  .section-header-workflow {
    display: flex;
    justify-content: flex-end;
    padding: 1em 2em 1em 0;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    font-size: 4em;
    background-color: #f4f3f1;
    color: #40b3a2;
    letter-spacing: 0.1em;
  }

  @media screen and (max-width: 1200px) {
    .section-header-workflow {
      padding: 1em 1em 1em 0;
      font-weight: 600;
      font-size: 3em;
    }
   }

   @media screen and (max-width: 1000px) {
    .workflow-page-container {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.5em;
      row-gap: 1.5em;
    }
    
    .workflow-card {
      margin: 0 0 7em 0;
    }
  
    .workflow-card-up {  
      margin: 7em 0 0 0;
    } 
   }

   @media screen and (max-width: 840px) {
    .section-header-workflow {
      padding: 1em 0.5em 1em 0;
      font-weight: 600;
      font-size: 3em;
    }

    .workflow-card {
      padding: 45px 30px;
      margin: 0 0 7em 0;
    }

    .workflow-card-up {  
      padding: 45px 30px;
      margin: 7em 0 0 0;
    } 

    .workflow-card img {
      width: 40px;
    }
  
    .workflow-card-up img {
      width: 40px;
    }
    
   }

   @media screen and (max-width: 640px) {
    .section-header-workflow {
      padding: 1em 0.5em 1em 0;
      font-weight: 600;
      font-size: 2.5em;
    }
   }

   @media screen and (max-width: 430px) {
    .section-header-workflow {
      padding: 1em 0 1em 0;
      font-size: 1.8em;
    }
   }

   @media screen and (max-width: 350px) {
    .workflow-page-container {
      column-gap: 0.5em;
    }
   }
