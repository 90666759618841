nav {
    background: transparent;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 10;
}

.navbar-control {
    background-color: #343434;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 0%;
    transition: all 0.3s ease 0s;
    z-index: -1;
    height: 100%;
}

.navbar-control.change {
    background-color: #343434;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    transition: width 0.3s ease 0s;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px;
}

.navbar-wrapper {
    display: flex;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0 2em 0 2em;
}

.nav-links a {
    text-decoration: none;
    background-color: transparent;
    color: #fff;
}

.nav-links ul {
    display: flex;
    list-style-type: none;
}

.nav-links li {
    font-size: 1.2em;
    color: #fff;
    padding: 1.5em 1.5em 1.5em 1.5em;
}

.socials-logo {
    display: flex;
    flex-direction: row;
    padding: 1.5em;
}

.linkedin-logo {
    padding: 0 0.5em 0 0.5em;
}

.github-logo {
    padding: 0 0.5em 0 0.5em;
}

.socials-logo img {
    display: flex;
    flex-direction: row;
    width: 30px;
}

.hamburger-menu {
    display: none;
}

@media (hover: hover) {
    .linkedin-logo img:hover {
        background-color: #e27d60;
        border-radius: 2.5px;
    }

    .github-logo img:hover {
        background-color: #e27d60;
        border-radius: 30px;
    }

    a:hover {
        color: #e27d60;
    }
}

@media screen and (max-width: 1100px) {
    .nav-links {
        display: none;
    }

    .hamburger-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background-color: transparent;
        margin: 2em 2em 0 0;
        border: none;
        cursor: pointer;
        height: 1.2em;
        width: 2em;
    }

    .hamburger-menu .line-1 {
        background-color: #fff;
        width: 35px;
        height: 2.5px;
        margin: 0 0 8px 0;
    }

    .hamburger-menu .line-2 {
        background-color: #fff;
        width: 23px;
        height: 2.5px;
        margin-bottom: 0.2em;
    }
}

@media screen and (max-width: 480px) {
    .navbar-wrapper {
        padding: 0 1em 0 1em;
    }
}

@media screen and (max-width: 380px) {
    .navbar-wrapper {
        padding: 0 0 0 0;
    }
}