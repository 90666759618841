* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  /* background-color: #e27d5f; */
  /* background-color: #85cdca; */
  /* background-color: #e8a87c; */
  /* background-color: #c38d9d; */
  /* background-color: #40b3a2; */
  /* background-color: #7dc2ad; */
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  /* font-style: italic; */
}



