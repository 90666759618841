#projects {
  background-color: #f4f3f1;
  padding-top: 4em;
}

.project1-color {
  width: 100%;
  /* background-color: #494f55; */
  background-color: #fff;
  padding: 4em 0 4em 0;
}

.project-space-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  padding: 0 60px;
}

.project-showcase-container {
  display: flex;
  align-items: center;
}

.project-left {
  margin: 0 8em 4em 12em;
  width: 35%;
}

.project-left ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.project-left-opposite {
  margin: 0 8em 4em 12em;
  width: 35%;
}

.project-left-opposite ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.project-left li {
  padding: 0.5em 0 0.5em 1.5em;
}

.project-left-opposite li {
  padding: 0.5em 0 0.5em 1.5em;
}

.arrow-list li {
  /* color: #fff; */
  color: #494f55;
  font-size: 1.2em;
}

.arrow-list li::before {
  content: "◇";
  margin-right: 8px;
  font-weight: bold;
}

.arrow-list-opposite li {
  color: #494f55;
  font-size: 1.2em;
}

.arrow-list-opposite li::before {
  content: "◇";
  margin-right: 8px;
  font-weight: bold;
}

.project-title {
  margin-bottom: 1.5em;
}

.project-title-opposite {
  margin-bottom: 1.5em;
}

.project-text {
  line-height: 2em;
  margin-bottom: 1em;
}

.project-text-opposite {
  line-height: 2em;
  margin-bottom: 1em;
}

.project-tools-title {
  font-weight: bold;
  margin-bottom: 1em;
}

.project-tools-title-opposite {
  font-weight: bold;
  margin-bottom: 1em;
}

.view-project {
  margin-top: 3em;
}

.view-project a {
  text-decoration: none;
  background-color: transparent;
  /* color: #fff; */
  color: #494f55;
  line-height: 1.5em;
  font-size: 1em;
  /* border: 1px solid #fff; */
  border: 1px solid #494f55;
  padding: 0.9em 2.5em;
}

.view-project-opposite {
  margin-top: 3em;
}

.view-project-opposite a {
  text-decoration: none;
  background-color: transparent;
  color: #494f55;
  line-height: 1.5em;
  font-size: 1em;
  border: 1px solid #494f55;
  padding: 0.9em 2.5em;
}


.project-right {
  width: 55%;
}

.project-left h3 {
  font-size: 2.5em;
  /* color: #fff; */
  color: #494f55;
}

.project-left p {
  font-size: 1.3em;
  /* color: #fff; */
  color: #494f55;
}

.project-left-opposite h3 {
  font-size: 2.5em;
  color: #494f55;
}

.project-left-opposite p {
  font-size: 1.3em;
  color: #494f55;
}

.project1-showcase {
  background: url('../images/alfies-smoked-salsa.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 480px;
  height: 635px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
}

.project2-color {
  width: 100%;
  background-color: #f4f3f1;
  padding: 4em 0 4em 0;
}

.project2-showcase {
  background: url('../images/hope-in-your-journey.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e0ecf5;
  width: 480px;
  height: 635px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
}

.project3-color {
  width: 100%;
  background-color: #494f55;
  padding: 4em 0 4em 0;
}

.project3-showcase {
  background: url('../images/elite-canine-fitness.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e0ecf5;
  width: 480px;
  height: 635px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
}

.project4-showcase {
  background: url('../images/DoorDash-lrg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e0ecf5;
  width: 480px;
  height: 635px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
}

.project5-showcase {
  background: url('../images/Mattress-lrg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e0ecf5;
  width: 480px;
  height: 635px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
}

.project6-showcase {
  background: url('../images/Teachable-lrg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e0ecf5;
  width: 480px;
  height: 635px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
}

@media (hover: hover) {
  .view-project a:hover {
    /* box-shadow: rgba(255, 255, 255, 0.2) 4px 4px 4px 0px; */
    box-shadow: rgba(73, 79, 85, 0.2) 4px 4px 4px 0px;
  }
}

@media (hover: hover) {
  .view-project-opposite a:hover {
    box-shadow: rgba(73, 79, 85, 0.2) 4px 4px 4px 0px;
  }
}

@media screen and (max-width: 1200px) {
  .project1-showcase {
    background: url('../images/alfies-smoked-salsa-1.png');
    width: 400px;
    height: 529px;
  }

  .project2-showcase {
    background: url('../images/hope-in-your-journey-1.png');
    width: 400px;
    height: 529px;
  }

  .project3-showcase {
    background: url('../images/elite-canine-fitness-1.png');
    width: 400px;
    height: 529px;
  }

  .project4-showcase {
    width: 400px;
    height: 529px;
  }

  .project5-showcase {
    width: 400px;
    height: 529px;
  }

  .project6-showcase {
    width: 400px;
    height: 529px;
  }

  .project-left h3 {
    font-size: 2em;
  }

  .project-left p {
    font-size: 1.1em;
  }

  .arrow-list li {
    font-size: 1em;
  }

  .view-project a {
    padding: 0.9em 2em;
  }

  .project-left {
    margin: 0 6em 4em 4em;
    width: 35%;
  }

  .project-left-opposite h3 {
    font-size: 2em;
  }

  .project-left-opposite p {
    font-size: 1.1em;
  }

  .arrow-list-opposite li {
    font-size: 1em;
  }

  .view-project-opposite a {
    padding: 0.9em 2em;
  }

  .project-left-opposite {
    margin: 0 6em 4em 4em;
    width: 35%;
  }

}

@media screen and (max-width: 1000px) {
  .project-space-container {
    flex-direction: column;
    align-items: center;
  }

  .project-left {
    margin: 0 0 4em 0;
    width: 55%;
  }

  .project-right {
    width: 400px;
  }

  .view-project {
    margin-top: 2em;
  }

  .project-left-opposite {
    margin: 0 0 4em 0;
    width: 55%;
  }

  .project-right {
    width: 400px;
  }

  .view-project-opposite {
    margin-top: 2em;
  }
}

@media screen and (max-width: 800px) {
  .project-left {
    width: 60%;
  }

  .project-left-opposite {
    width: 60%;
  }
}

@media screen and (max-width: 650px) {
  .project-left {
    width: 70%;
  }

  .project-left-opposite {
    width: 70%;
  }
}

@media screen and (max-width: 625px) {
  .project-left {
    width: 75%;
  }

  .project-left-opposite {
    width: 75%;
  }
}

@media screen and (max-width: 600px) {
  .project-left {
    width: 80%;
  }

  .project-left-opposite {
    width: 80%;
  }
}

@media screen and (max-width: 550px) {
  .project-left {
    width: 85%;
  }

  .project-left-opposite {
    width: 85%;
  }
}

@media screen and (max-width: 500px) {
  .project-left {
    width: 90%;
  }

  .project-left-opposite {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .project-right {
    width: 350px;
  }

  .project-left {
    width: 70%;
  }

  .project-left-opposite {
    width: 70%;
  }

  .project1-showcase {
    background: url('../images/alfies-smoked-salsa-2.png');
    width: 350px;
    height: 463px;
  }

  .project2-showcase {
    background: url('../images/hope-in-your-journey-2.png');
    width: 350px;
    height: 470px;
  }

  .project3-showcase {
    background: url('../images/elite-canine-fitness-2.png');
    width: 350px;
    height: 463px;
  }

  .project4-showcase {
    width: 350px;
    height: 463px;
  }

  .project5-showcase {
    width: 350px;
    height: 463px;
  }

  .project6-showcase {
    width: 350px;
    height: 463px;
  }

  .project-left h3 {
    font-size: 1.5em;
  }

  .project-left p {
    line-height: 1.7em;
    font-size: 1em;
  }

  .arrow-list li {
    font-size: 0.9em;
  }

  .view-project a {
    padding: 0.9em 2em;
  }

  .view-project a {
    font-size: 0.8em;
  }

  .project-space-container {
    padding: 0;
  }

  .project-left-opposite h3 {
    font-size: 1.5em;
  }

  .project-left-opposite p {
    line-height: 1.7em;
    font-size: 1em;
  }

  .arrow-list-opposite li {
    font-size: 0.9em;
  }

  .view-project-opposite a {
    padding: 0.9em 2em;
  }

  .view-project-opposite a {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 400px) {
  .project-right {
    width: 280px;
  }

  .project-left {
    width: 70%;
  }

  .project-left-opposite {
    width: 70%;
  }

  .project1-showcase {
    background: url('../images/alfies-smoked-salsa-3.png');
    width: 280px;
    height: 370px;
  }

  .project2-showcase {
    background: url('../images/hope-in-your-journey-3.png');
    width: 280px;
    height: 370px;
  }

  .project3-showcase {
    background: url('../images/elite-canine-fitness-3.png');
    width: 280px;
    height: 370px;
  }

  .project4-showcase {
    width: 280px;
    height: 370px;
  }

  .project5-showcase {
    width: 280px;
    height: 370px;
  }

  .project6-showcase {
    width: 280px;
    height: 370px;
  }

  .project-left h3 {
    font-size: 1.2em;
  }

  .project-left p {
    line-height: 1.7em;
    font-size: 0.95em;
  }

  .arrow-list li {
    font-size: 0.85em;
  }

  .view-project a {
    padding: 0.9em 2em;
    font-size: 0.75em;
  }

  .project-left-opposite h3 {
    font-size: 1.2em;
  }

  .project-left-opposite p {
    line-height: 1.7em;
    font-size: 0.95em;
  }

  .arrow-list-opposite li {
    font-size: 0.85em;
  }

  .view-project-opposite a {
    padding: 0.9em 2em;
    font-size: 0.75em;
  }
}