.mobile-overlay {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 0%;
    transform-origin: right;
    height: calc(100vh - 80px);
    margin-top: 80px;
    transition: width 350ms ease 20ms;
    background-color: #343434;
    height: 100%;
    z-index: 1000;
}

.nav-cover {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 0%;
    transition: width 350ms ease 20ms;
    background-color: #343434;
    height: 80px;
    z-index: 100;
}

.mobile-overlay-text {
    position: fixed;
    flex-direction: column;
    width: 100%;
    padding: 1em 0 0 2em;
    background-color: #343434;
    height: calc(100vh - 80px);
    margin-top: 80px;
}

.mobile-overlay ul {
    list-style-type: none;
}

.mobile-overlay li {
    padding-top: 2em;
    font-size: 1.8em;
}

.mobile-overlay a {
    background-color: transparent;
    text-decoration: none;
    color: #fff;
}

@media (hover: hover) {

    .hamburger-menu:hover .line-1,
    .hamburger-menu:hover .line-2 {
        background-color: #e27d60;
    }
}


@media screen and (max-width: 1000px) {
    .mobile-overlay.display {
        position: fixed;
        display: flex;
        flex-direction: column;
        transition: width 350ms ease;
        background-color: #343434;
        width: 100%;
        height: calc(100vh - 80px);
        margin-top: 80px;
    }

    .nav-cover.display {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        transform-origin: left;
        transition: width 350ms ease;
        background-color: #343434;
        height: 80px;
    }

    .mobile-overlay.display ul {
        list-style-type: none;
    }

    .mobile-overlay.display a {
        background-color: transparent;
        text-decoration: none;
        color: #fff;
    }

    .mobile-overlay-text.display {
        position: fixed;
        flex-direction: column;
        width: 100%;
        padding: 0 0 0 3em;
        background-color: #343434;
        height: calc(100vh - 80px);
        margin-top: 80px;
    }

    .mobile-overlay-text.display a:hover {
        color: #e27d60;
    }
}